import * as React from "react"
import { gql } from "@apollo/client"
import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Center,
  Divider,
  HStack,
  ModalFooter,
  Spinner,
  Stack,
  Text,
  useBoolean,
} from "@chakra-ui/react"
import dayjs from "dayjs"

import { PaymentMethod, useCreateInvoiceMutation } from "lib/graphql"
import {
  GetEntityInvoicesDocument,
  useCreateManyInvoicesMutation,
  useGetUserCompanyEntitiesQuery,
} from "lib/graphql"
import { useForm } from "lib/hooks/useForm"
import { useS3BulkUpload, useS3Upload } from "lib/hooks/useS3"
import { useToast } from "lib/hooks/useToast"
import { useBetterTranslation } from "lib/hooks/useTranslation"
import { YEARS } from "lib/static/years"
import { UPLOAD_PATHS } from "lib/uploadPaths"
import yup, { UploadSchema } from "lib/yup"
import { Input } from "components/Input"

import { DateInput } from "./DateInput"
import { FileInput } from "./FileInput"
import { Form } from "./Form"
import { MultiFileInput } from "./MultiFileInput"
import { Select } from "./Select"
import { useMe } from "lib/hooks/useMe"
import { usePaymentMethodOptions } from "lib/hooks/usePaymentMethodOptions"

const _ = gql`
  query GetUserCompanyEntities {
    me {
      id
      companyEntities {
        ...EntityItem
      }
    }
  }
`

interface Props {
  onClose: () => void
  selectedEntity?: { name: string; id: string } | null
  selectedDate?: { year: string | undefined; quarter: string | undefined } | null
  files?: File[]
}

export function CreateInvoiceForm(props: Props) {
  const bt = useBetterTranslation()

  const [isMulti, { on, off }] = useBoolean(props.files && props.files.length > 1)
  const [selectedEntity, setSelectedEntity] = React.useState<{ name: string; id: string } | null | undefined>(
    props.selectedEntity,
  )
  const { data, loading: entitiesLoading } = useGetUserCompanyEntitiesQuery()

  const companyEntities = data?.me?.companyEntities

  React.useEffect(() => {
    if (companyEntities && companyEntities.length === 1 && !props.selectedEntity) {
      setSelectedEntity({ name: companyEntities[0].name, id: companyEntities[0].id })
    }
  }, [companyEntities, props.selectedEntity])

  if (!companyEntities || entitiesLoading) return null
  if (!selectedEntity)
    return (
      <ButtonGroup>
        {companyEntities.map((entity) => (
          <Button key={entity.id} onClick={() => setSelectedEntity({ name: entity.name, id: entity.id })}>
            {entity.name}
          </Button>
        ))}
      </ButtonGroup>
    )

  return (
    <Stack spacing={4}>
      <HStack>
        <Button
          colorScheme={isMulti ? "gray" : "pink"}
          variant={isMulti ? "outline" : "solid"}
          w="100%"
          onClick={off}
        >
          {bt({ en: "Single", nl: "Enkel" })}
        </Button>
        <Button
          colorScheme={isMulti ? "pink" : "gray"}
          variant={isMulti ? "solid" : "outline"}
          w="100%"
          onClick={on}
        >
          {bt({ en: "Multiple", nl: "Meerdere" })}
        </Button>
      </HStack>
      <Divider />
      {isMulti ? (
        <CreateManyInvoicesForm {...props} selectedEntity={selectedEntity} />
      ) : (
        <CreateSingleInvoiceForm {...props} selectedEntity={selectedEntity} />
      )}
    </Stack>
  )
}

const __ = gql`
  mutation CreateInvoice($data: InvoiceCreateInput!) {
    createInvoice(data: $data) {
      id
      description
      file
      entityId
      date
    }
  }
`

const InvoiceFormSchema = yup.object().shape({
  date: yup.string().required("Required"),
  description: yup.string().nullIfEmpty(),
  paymentMethod: yup.string().nullIfEmpty(),
  file: UploadSchema,
})

function CreateSingleInvoiceForm({
  onClose,
  selectedEntity,
  files,
}: Props & { selectedEntity: { name: string; id: string } }) {
  const toast = useToast()
  const bt = useBetterTranslation()
  const paymentMethodOptions = usePaymentMethodOptions()

  const defaultValues = {
    description: files?.[0]?.name.split(".")[0] || "",
    date: "",
    file: files?.[0] || "",
    paymentMethod: "",
  }
  const form = useForm({ defaultValues, schema: InvoiceFormSchema })

  const [upload] = useS3Upload()
  const [createInvoice, { loading }] = useCreateInvoiceMutation()

  const handleUpdate = async (data: yup.InferType<typeof InvoiceFormSchema>) => {
    let fileName = ""
    let file = data.file && typeof data.file !== "string" ? data.file : undefined
    if (!file)
      return toast({
        status: "error",
        description: bt({ en: "Please upload a file", nl: "Upload een bestand" }),
      })

    if (file && typeof file !== "string") {
      try {
        const uploadedFile = await upload(file, { path: UPLOAD_PATHS.entityInvoice(selectedEntity.id) })
        fileName = uploadedFile.fileName
        file = uploadedFile.fileKey
      } catch {
        return toast({
          status: "error",
          description: bt({
            en: "Error uploading, please try again",
            nl: "Fout bij het uploaden, probeer het opnieuw.",
          }),
        })
      }
    }

    return form.handler(
      () =>
        createInvoice({
          variables: {
            data: {
              ...data,
              description: data.description || fileName,
              paymentMethod: data.paymentMethod as PaymentMethod,
              file,
              entity: { connect: { id: selectedEntity.id } },
            },
          },
          refetchQueries: [GetEntityInvoicesDocument],
        }),
      {
        onSuccess: (_, toast) => {
          onClose()
          toast({ description: bt({ en: "Invoice uploaded", nl: "Omzetfactuur uploaden" }) })
        },
      },
    )
  }

  const handleCancel = () => {
    onClose()
    form.reset(defaultValues)
  }

  if (loading)
    return (
      <Center h="100%">
        <Spinner />
      </Center>
    )

  return (
    <Form {...form} onSubmit={handleUpdate}>
      <Stack spacing="8">
        <Text fontSize="2xl" fontWeight="bold">
          {selectedEntity?.name}
        </Text>
        <Input
          name="description"
          variant="outline"
          label={bt({ en: "Description", nl: "Beschrijving" })}
          subLabel={bt({ en: "optional", nl: "optioneel" })}
          colorScheme="pink"
        />
        <Select
          name="paymentMethod"
          label={bt({ en: "Mark as paid via", nl: "Markeren als betaald via" })}
          options={paymentMethodOptions}
          isClearable
        />
        <FileInput name="file" />
        <DateInput label={bt({ en: "Date", nl: "Datum" })} name="date" />
        {form.getValues().date && (
          <Alert>
            <AlertIcon />
            {bt({
              en: `Uploading to Q${dayjs(form.getValues().date).quarter()} ${dayjs(
                form.getValues().date,
              ).year()}`,
              nl: `Uploaden naar Q${dayjs(form.getValues().date).quarter()} ${dayjs(
                form.getValues().date,
              ).year()}`,
            })}
          </Alert>
        )}
        <ModalFooter p={0}>
          <ButtonGroup>
            {form.formState.isDirty && (
              <Button variant="ghost" size="sm" onClick={handleCancel}>
                {bt({ en: "Cancel", nl: "Annuleer" })}
              </Button>
            )}
            <Button
              type="submit"
              isDisabled={form.formState.isSubmitting || (!form.formState.isDirty && !files) || loading}
              isLoading={form.formState.isSubmitting || loading}
              colorScheme="pink"
              size="sm"
            >
              {bt({ en: "Upload", nl: "Uploaden" })}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Stack>
    </Form>
  )
}
const ___ = gql`
  mutation CreateManyInvoices($data: [InvoiceCreateManyInput!]!) {
    createManyInvoices(data: $data)
  }
`

const InvoicesFormSchema = yup.object().shape({
  year: yup.number().required("Required"),
  quarter: yup.number().required("Required"),
  files: yup.array(UploadSchema.required("Required")).required("Required"),
})

function CreateManyInvoicesForm({
  onClose,
  selectedEntity,
  files,
  ...props
}: Props & { selectedEntity: { name: string; id: string } }) {
  const { me } = useMe()
  const toast = useToast()
  const bt = useBetterTranslation()

  const quarters = [1, 2, 3, 4]

  const defaultValues = {
    quarter: props.selectedDate?.quarter
      ? parseInt(props.selectedDate?.quarter?.split("Q")[1])
      : dayjs().quarter(),
    year: props.selectedDate?.year ? parseInt(props.selectedDate?.year) : dayjs().year(),
    files: files || [],
  }
  const form = useForm({ defaultValues, schema: InvoicesFormSchema })

  const [upload] = useS3BulkUpload({ path: UPLOAD_PATHS.entityInvoice(selectedEntity.id) })
  const [createInvoices, { loading }] = useCreateManyInvoicesMutation()

  const handleUpdate = async (data: typeof defaultValues) => {
    if (!me) return
    const date = dayjs().year(data.year).quarter(data.quarter).startOf("quarter").add(1, "day").toISOString()

    if (data.files.length === 0)
      return toast({
        description: bt({
          en: "Please upload at least one file",
          nl: "Upload a.u.b. ten minste één bestand",
        }),
        status: "error",
      })

    const uploadedFiles = await upload(data.files)

    if (!uploadedFiles)
      return toast({
        description: bt({
          en: "Error uploading, please try again",
          nl: "Fout bij het uploaden, probeer het opnieuw.",
        }),
        status: "error",
      })

    return form.handler(
      () =>
        createInvoices({
          variables: {
            data: uploadedFiles.map((file) => ({
              file: file.fileKey,
              description: file.fileName,
              date: date,
              entityId: selectedEntity.id,
              uploaderId: me.id,
            })),
          },
          refetchQueries: [GetEntityInvoicesDocument],
        }),
      {
        onSuccess: (_, toast) => {
          onClose()
          toast({
            description: bt({
              en: `${uploadedFiles.length} Invoices uploaded`,
              nl: `${uploadedFiles.length} Omzetfactuur uploaden`,
            }),
          })
        },
      },
    )
  }

  const handleCancel = () => {
    onClose()
    form.reset(defaultValues)
  }

  if (loading)
    return (
      <Center h="100%">
        <Spinner />
      </Center>
    )

  return (
    <Form {...form} onSubmit={handleUpdate}>
      <Stack spacing="8">
        <Text fontSize="2xl" fontWeight="bold">
          {selectedEntity?.name}
        </Text>
        <MultiFileInput name="files" />
        <Select name="year" label={bt({ en: "Year", nl: "Jaar" })} options={YEARS} />
        <Select name="quarter" label={bt({ en: "Quarter", nl: "Quarter" })} options={quarters} />
        <ModalFooter p={0}>
          <ButtonGroup>
            {form.formState.isDirty && (
              <Button variant="ghost" size="sm" onClick={handleCancel}>
                {bt({ en: "Cancel", nl: "Annuleer" })}
              </Button>
            )}
            <Button
              type="submit"
              isDisabled={form.formState.isSubmitting || (!form.formState.isDirty && !files) || loading}
              isLoading={form.formState.isSubmitting || loading}
              colorScheme="pink"
              size="sm"
            >
              {bt({ en: "Upload", nl: "Uploaden" })}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Stack>
    </Form>
  )
}
