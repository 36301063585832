import * as React from "react"
import { FiBell } from "react-icons/fi"
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import dayjs from "dayjs"

import type { Quarter, SignableFormTaskFragment } from "lib/graphql"
import { useGetUserTasksQuery } from "lib/graphql"
import { useMe } from "lib/hooks/useMe"
import { useBetterTranslation } from "lib/hooks/useTranslation"

import { CreateExpenseForm } from "./CreateExpenseForm"
import { CreateInvoiceForm } from "./CreateInvoiceForm"
import { Modal } from "./Modal"
import { NoData } from "./NoData"
import { TaskUploadType } from "./UserTasks"
import { gatherTasks } from "lib/helpers/gatherTasks"
import { TasksList } from "./TasksList"

export function TasksPopover({ userId }: { userId: string }) {
  const { me } = useMe()
  const bt = useBetterTranslation()

  const modalProps = useDisclosure()
  const [selectedUpload, setSelectedUpload] = React.useState<TaskUploadType | null>(null)
  const [selectedEntity, setSelectedEntity] = React.useState<{ name: string; id: string } | null>(null)

  const { data, loading } = useGetUserTasksQuery({
    variables: { userId },
    pollInterval: 30_000,
    skip: !!!userId,
  })
  const entities = data?.me?.entities
  const entitiesWithTasks = entities?.filter(
    (entity) =>
      entity.hasUpcomingVatDeadline ||
      entity.pendingIncomeData.length > 0 ||
      entity.pendingReports.length > 0 ||
      entity.pendingSignableForms.length > 0,
  )
  const pendingPersonalSignableForms: SignableFormTaskFragment[] = React.useMemo(
    () => data?.me?.pendingPersonalSignableForms || [],
    [data?.me?.pendingPersonalSignableForms],
  )

  const handleModalClose = () => {
    modalProps.onClose()
    setSelectedUpload(null)
  }

  const defaultQuarter = `Q${dayjs()
    .startOf("Q")
    .subtract(1, "days")
    .startOf("Q")
    .quarter()
    .toString()}` as Quarter
  const defaultYear: string = dayjs().startOf("Q").subtract(1, "days").startOf("Q").year().toString()

  const allTasks = React.useMemo(() => {
    return gatherTasks(entitiesWithTasks, pendingPersonalSignableForms)
  }, [entitiesWithTasks, pendingPersonalSignableForms])
  const taskCount = allTasks.length

  if (!me) return null

  return (
    <Popover placement="top-end">
      <PopoverTrigger>
        <IconButton
          mr={2}
          aria-label="Open Tasks"
          variant="ghost"
          borderRadius="full"
          icon={
            <Box pos="relative">
              <Box as={FiBell} size="22" p={0} />
              {taskCount > 0 && (
                <Flex
                  pos="absolute"
                  top={taskCount > 9 ? "-9px" : "-1px"}
                  right={taskCount > 9 ? "-9px" : "-1px"}
                  bg="pink.500"
                  w={taskCount > 9 ? "20px" : "15px"}
                  h={taskCount > 9 ? "20px" : "15px"}
                  borderRadius="50%"
                  alignItems="center"
                  justifyContent="center"
                  color="white"
                  fontSize="12px"
                >
                  {taskCount > 9 ? "9+" : taskCount}
                </Flex>
              )}
            </Box>
          }
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent w="600px" p={0}>
          <PopoverArrow />
          <PopoverHeader fontWeight="bold" fontSize="xl" py={2}>
            <Box position="relative" display="inline-block">
              <Text>
                {bt({ en: "Tasks", nl: "Taken" })} - {taskCount}
              </Text>
            </Box>
          </PopoverHeader>
          <PopoverBody p={0} maxH="300px" overflowY="scroll">
            <Stack spacing={4} px={4} pb={4}>
              {loading ? (
                <Center w="100%">
                  <Spinner size="lg" color="pink.500" />
                </Center>
              ) : taskCount > 0 ? (
                <TasksList
                  tasks={allTasks}
                  setSelectedEntity={setSelectedEntity}
                  onOpen={modalProps.onOpen}
                />
              ) : (
                <Center h="200px">
                  <NoData>{bt({ en: "No tasks", nl: "Geen taken" })}</NoData>
                </Center>
              )}
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
      <Modal
        {...modalProps}
        onClose={handleModalClose}
        title={
          selectedUpload === "expense"
            ? bt({ en: "Upload expense", nl: "Uitgave uploaden" })
            : selectedUpload === "invoice"
            ? bt({ en: "Upload invoice", nl: "Omzetfactuur uploaden" })
            : bt({ en: "Upload documnents", nl: "Documenten uploaden" })
        }
        size="2xl"
      >
        {selectedUpload === "expense" ? (
          <>
            <CreateExpenseForm
              onClose={handleModalClose}
              selectedEntity={selectedEntity}
              selectedDate={{ year: defaultYear, quarter: defaultQuarter }}
            />
          </>
        ) : selectedUpload === "invoice" ? (
          <>
            <CreateInvoiceForm
              onClose={handleModalClose}
              selectedEntity={selectedEntity}
              selectedDate={{ year: defaultYear, quarter: defaultQuarter }}
            />
          </>
        ) : (
          <>
            <ButtonGroup>
              <Button onClick={() => setSelectedUpload("expense")}>
                {bt({ en: "Expense", nl: "Uitgave" })}
              </Button>
              <Button onClick={() => setSelectedUpload("invoice")}>
                {bt({ en: "Invoice", nl: "Factuur" })}
              </Button>
            </ButtonGroup>
          </>
        )}
      </Modal>
    </Popover>
  )
}
