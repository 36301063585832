import * as React from "react"
import { Box, Center, Icon, Spinner, Stack, useColorModeValue, useDisclosure, Text } from "@chakra-ui/react"
import { useRouter } from "next/router"
import { FileRejection, useDropzone } from "react-dropzone"
import * as Sentry from "@sentry/nextjs"

import { Role } from "lib/graphql"
import { useMe } from "lib/hooks/useMe"

import { Limiter } from "./Limiter"
import { Nav } from "./Nav"
import { useValidateFiles } from "lib/hooks/useValidateFiles"
import { BANK_STATEMENT_FILETYPES, CustomFile } from "lib/helpers/isValidFile"
import { Footer } from "./Footer"
import { FiUpload } from "react-icons/fi"
import { Modal } from "./Modal"
import { CreateExpenseForm } from "./CreateExpenseForm"
import { useBetterTranslation } from "lib/hooks/useTranslation"
import { CreateInvoiceForm } from "./CreateInvoiceForm"
import { CreateBankStatementForm } from "./CreateBankStatementForm"
import { CreateAttachmentForm } from "./CreateAttachmentForm"
import { UploadType, UPLOAD_TYPE } from "lib/static/uploadType"
import { capitalize } from "lib/helpers/text"

interface Props {
  children: React.ReactNode
}

export function HomeLayout(props: Props) {
  const { me, loading } = useMe()
  const bt = useBetterTranslation()
  const validateFiles = useValidateFiles()
  const { asPath, replace, query } = useRouter()

  const createExpenseProps = useDisclosure()
  const createInvoiceProps = useDisclosure()
  const createBankStatementProps = useDisclosure()
  const createAttachmentProps = useDisclosure()

  const year = query.year as string | undefined
  const quarter = query.quarter as string | undefined

  const [files, setFiles] = React.useState<CustomFile[]>([])

  const uploadType = React.useMemo(() => {
    return asPath.includes("expenses")
      ? UploadType.Expense
      : asPath.includes("invoices")
      ? UploadType.Invoice
      : asPath.includes("bank-statements")
      ? UploadType.BankStatement
      : asPath.includes("attachments")
      ? UploadType.Attachment
      : undefined
  }, [asPath])

  const onDrop = React.useCallback(
    async (files: File[], fileRejections: FileRejection[]) => {
      const customFiletypes = uploadType === UploadType.BankStatement ? BANK_STATEMENT_FILETYPES : undefined
      const validatedFiles = await validateFiles(
        files,
        fileRejections,
        "file",
        undefined,
        undefined,
        undefined,
        customFiletypes,
      )
      if (validatedFiles && validatedFiles.length > 0) {
        setFiles(validatedFiles)
        switch (uploadType) {
          case UploadType.Expense:
            createExpenseProps.onOpen()
            break
          case UploadType.Invoice:
            createInvoiceProps.onOpen()
            break
          case UploadType.BankStatement:
            createBankStatementProps.onOpen()
            break
          case UploadType.Attachment:
            createAttachmentProps.onOpen()
            break
        }
      } else {
        console.error("HomeLayout: Invalid files")
        Sentry.captureException("HomeLayout: Invalid files")
      }
    },
    [
      uploadType,
      validateFiles,
      createExpenseProps,
      createInvoiceProps,
      createBankStatementProps,
      createAttachmentProps,
    ],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: true, multiple: true })

  const bg = useColorModeValue("white", "gray.900")
  const borderColor = useColorModeValue("gray.100", "gray.900")
  const overlayBgColor = useColorModeValue("rgba(255, 255, 255, 0.6)", "rgba(0, 0, 0, 0.6)")

  React.useEffect(() => {
    if (loading) return
    if (!me) {
      replace(`/login?redirect=${asPath}`)
    } else if (me.role === Role.Admin) {
      replace(`/admin/entities`)
    }
  }, [loading, me, replace, asPath])

  if (loading || !me || me.role === Role.Admin) {
    return (
      <Center minH="100vh">
        <Spinner />
      </Center>
    )
  }

  return (
    <Box className="h-full" {...getRootProps()}>
      <input {...getInputProps()} />

      {/* Dropzone overlay */}
      {isDragActive && uploadType && (
        <Box bg={overlayBgColor} zIndex={1000} pos="absolute" top={0} left={0} h="100vh" w="100vw" p={5}>
          <Box h="100%" w="100%" borderStyle="dashed" borderColor="blue.400" borderWidth="4px" rounded="3xl">
            <Center h="100%">
              <Stack
                p={4}
                spacing={4}
                bg={bg}
                rounded="xl"
                align="center"
                border="1px solid"
                borderColor={borderColor}
              >
                <Icon boxSize="30px" as={FiUpload} />
                <Text fontSize="xl">
                  {bt({
                    en: `Upload ${UPLOAD_TYPE[uploadType].en}`,
                    nl: `${capitalize(UPLOAD_TYPE[uploadType].nl)} uploaden`,
                  })}
                </Text>
              </Stack>
            </Center>
          </Box>
        </Box>
      )}

      {/* Dropzone modals */}
      <Modal
        {...createExpenseProps}
        title={bt({ en: "Upload expense", nl: "Uitgave uploaden" })}
        size="2xl"
        isCentered
      >
        <CreateExpenseForm
          onClose={createExpenseProps.onClose}
          selectedDate={{ year, quarter }}
          files={files}
        />
      </Modal>
      <Modal
        {...createInvoiceProps}
        title={bt({ en: "Add invoice", nl: "Omzetfactuur toevoegen" })}
        size="2xl"
        isCentered
      >
        <CreateInvoiceForm
          onClose={createInvoiceProps.onClose}
          selectedDate={{ year, quarter }}
          files={files}
        />
      </Modal>
      <Modal
        {...createBankStatementProps}
        title={bt({ en: "Add bank statement", nl: "Bankafschrift toevoegen" })}
        size="2xl"
        isCentered
      >
        <CreateBankStatementForm onClose={createBankStatementProps.onClose} files={files} />
      </Modal>
      <Modal
        {...createAttachmentProps}
        title={bt({ en: "Add other attachment", nl: "Bijlages & documenten toevoegen" })}
        size="2xl"
        isCentered
      >
        <CreateAttachmentForm onClose={createAttachmentProps.onClose} files={files} />
      </Modal>

      <Box minH="100vh" pos="relative">
        <Nav />
        <Limiter pt="80px" maxW="1280px">
          {props.children}
        </Limiter>
      </Box>
      <Footer />
    </Box>
  )
}
