import * as React from "react"
import { Box, HStack, Stack, Text, VStack } from "@chakra-ui/react"
import NextLink from "next/link"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import quarterOfYear from "dayjs/plugin/quarterOfYear"

dayjs.extend(advancedFormat)
dayjs.extend(quarterOfYear)

import { FiChevronRight } from "react-icons/fi"

import type { UserTaskEntityFragment } from "lib/graphql"
import { useBetterTranslation } from "lib/hooks/useTranslation"
import { REPORT_TYPES } from "lib/static/reportType"
import { TaskItem } from "lib/helpers/gatherTasks"

interface Props {
  tasks: TaskItem[]
  setSelectedEntity: React.Dispatch<React.SetStateAction<{ name: string; id: string }>>
  onOpen: () => void
}

export function TasksList({ tasks, setSelectedEntity, onOpen }: Props) {
  const bt = useBetterTranslation()

  return (
    <Stack spacing={2}>
      {tasks?.map((task, i) => (
        <Stack key={i} spacing={2}>
          {task.type === "vat" && task.entity && (
            <VatTaskItem entity={task.entity} openModal={onOpen} setSelectedEntity={setSelectedEntity} />
          )}
          {task.type === "signableForm" && task.signableForm && (
            <NextLink href={`/documents/${task.signableForm.id}`}>
              <HStack
                alignItems="center"
                w="100%"
                cursor="pointer"
                backgroundColor="pink.500"
                justify="space-between"
                py={2}
                px={4}
              >
                <HStack>
                  <VStack spacing={0} alignItems="flex-start" marginRight="auto">
                    <Text fontSize="md" fontWeight="bold" mr={4} color="white">
                      {bt({ en: "Document signing required", nl: "Document ondertekenen vereist" })}
                    </Text>
                    <Text fontSize="sm" fontWeight="bold" color="pink.100">
                      {task.entity
                        ? `${task.entity.name} - ${task.signableForm.templateName}`
                        : task.signableForm.templateName}
                    </Text>
                  </VStack>
                </HStack>

                <HStack spacing={0}>
                  <Box boxSize="18px" color="white" as={FiChevronRight} />
                </HStack>
              </HStack>
            </NextLink>
          )}
          {task.type === "report" && task.entity && task.report && (
            <NextLink
              href={`/entities/${task.entity?.id}/${task.report.year}/${
                task.report.quarter ? task.report.quarter + "/" : ""
              }report/${task.report.id}`}
            >
              <HStack
                alignItems="center"
                w="100%"
                cursor="pointer"
                backgroundColor="pink.500"
                justify="space-between"
                py={2}
                px={4}
              >
                <HStack>
                  <VStack spacing={0} alignItems="flex-start" marginRight="auto">
                    <Text fontSize="md" fontWeight="bold" mr={4} color="white">
                      {bt({ en: "Report review required", nl: "Beoordeling van het volgende vereist" })}
                    </Text>
                    <Text fontSize="sm" fontWeight="bold" color="pink.100">
                      {task.entity.name} - {REPORT_TYPES[task.report.type]} -{" "}
                      {task.report.quarter ? task.report.quarter : ""} {task.report.year}
                    </Text>
                  </VStack>
                </HStack>

                <HStack spacing={0}>
                  <Box boxSize="18px" color="white" as={FiChevronRight} />
                </HStack>
              </HStack>
            </NextLink>
          )}
          {task.type === "incomeData" && task.entity && task.incomeData && (
            <NextLink
              href={`/entities/${task.entity?.id}/${task.incomeData.year}/income-data/${task.incomeData.id}`}
            >
              <HStack
                alignItems="center"
                w="100%"
                cursor="pointer"
                backgroundColor="pink.500"
                justify="space-between"
                py={2}
                px={4}
              >
                <HStack>
                  <VStack spacing={0} alignItems="flex-start" marginRight="auto">
                    <Text fontSize="md" fontWeight="bold" mr={4} color="white">
                      {bt({
                        en: "Income data review required",
                        nl: "Beoordeling van inkomensgegevens vereist",
                      })}
                    </Text>
                    <Text fontSize="sm" fontWeight="bold" color="pink.100">
                      {task.entity.name} - {task.incomeData.year}
                    </Text>
                  </VStack>
                </HStack>
                <HStack spacing={0}>
                  <Box boxSize="18px" color="white" as={FiChevronRight} />
                </HStack>
              </HStack>
            </NextLink>
          )}
        </Stack>
      ))}
    </Stack>
  )
}

interface VatTaskItemProps {
  entity: UserTaskEntityFragment
  openModal: () => void
  setSelectedEntity: React.Dispatch<React.SetStateAction<{ name: string; id: string } | null>>
}

function VatTaskItem({ entity, openModal, setSelectedEntity }: VatTaskItemProps) {
  const bt = useBetterTranslation()
  const handleUpload = () => {
    setSelectedEntity({ name: entity.name, id: entity.id })
    openModal()
  }
  return (
    <HStack
      onClick={handleUpload}
      alignItems="center"
      w="100%"
      cursor="pointer"
      backgroundColor="pink.500"
      justify="space-between"
      py={2}
      px={4}
    >
      <VStack spacing={0} alignItems="flex-start" marginRight="auto">
        <Text fontSize="md" fontWeight="bold" color="white">
          {bt({
            en: `Upload all invoices and expenses before the ${dayjs()
              .startOf("Q")
              .add(11, "days")
              .format("Do")} of ${dayjs().startOf("Q").add(11, "days").format("MMMM")}`,
            nl: `Upload alle facturen en uitgaven voor ${dayjs()
              .startOf("Q")
              .add(11, "days")
              .format("Do")} ${dayjs().startOf("Q").add(11, "days").format("MMMM")}`,
          })}
        </Text>
        <Text fontWeight="bold" fontSize="sm" color="pink.100">
          {entity.name} - Omzetbelasting - Q{dayjs().startOf("Q").subtract(1, "days").quarter()}{" "}
          {dayjs().startOf("Q").subtract(1, "days").year()}
        </Text>
      </VStack>
      <HStack spacing={0}>
        <Box boxSize="18px" color="white" as={FiChevronRight} />
      </HStack>
    </HStack>
  )
}
